import React, { useState } from "react";
import { LogoContainer, Side, LogoSvg, Arrow } from "./style";
import logo from "../logo.svg";

export default () => {
  const [isHover, setHover] = useState(false);
  return (
    <LogoContainer>
      <Side />
      <LogoSvg logo={logo} onMouseOver={() => setHover(true)} onMouseLeave={() => setHover(false)} isHover={isHover} />
      {/* <FakeShadow /> */}
      <Arrow />
    </LogoContainer>
  );
};
