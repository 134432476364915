import React from "react";
import {
  AreaContainer,
  TitleContainer,
  Title,
  Subtitle,
  AreaBox,
  AreaIcon,
  AreaTitle,
  AreaDescription,
  AreaItems,
  AreaText,
  Footer,
  FooterContent,
} from "./style";
import icon1 from "../icon/icon-1.svg";
import icon2 from "../icon/icon-2.svg";
import icon3 from "../icon/icon-3.svg";
import icon4 from "../icon/icon-4.svg";

const AreaItem = ({ icon, title, description }) => (
  <AreaBox>
    <AreaIcon icon={icon} />
    <AreaText>
      <AreaTitle>{title}</AreaTitle>
      <AreaDescription>{description}</AreaDescription>
    </AreaText>
  </AreaBox>
);

const Area = () => {
  return (
    <>
      <AreaContainer>
        <TitleContainer>
          <Title>Business Area of Plumbug Studio</Title>
          <Subtitle>
            플럼버그 스튜디오는 웹, 모바일 서비스 개발과 더불어 블록체인을 핵심 기술로 여러분께 제공합니다. 또한 다양한
            응용 시스템 개발, 컨설팅과 더불어 프로젝트의 기획, 디자인까지 올인원으로 제공해드립니다.
          </Subtitle>
        </TitleContainer>
        <AreaItems>
          <AreaItem
            icon={icon2}
            title="웹 / 모바일 서비스 개발"
            description="반응형 모던 웹, SPA와 하이브리드 앱, 안드로이드 및 IOS 앱을 개발합니다."
          />
          <AreaItem
            icon={icon1}
            title="메인넷 개발"
            description="자체 메인넷을 구축합니다. 중요한 비즈니스 포인트가 될 수 있으며 컨트랙트 수수료 이슈, 트랜잭션 속도 이슈를 해결할 수 있습니다."
          />
          <AreaItem
            icon={icon3}
            title="생태계 구성"
            description="멀티체인 지갑, 탈중앙화 거래소, NFT 마켓플레이스 등 블록체인 비즈니스에서 반드시 필요한 어플리케이션 등을 개발합니다."
          />
          <AreaItem
            icon={icon4}
            title="블록체인 DAPP"
            description="자체 메인넷 및 이더리움, 이오스, 트론, BNB, 폴리곤, 클레이튼 등의 네트워크에서 이용 가능한 스마트 컨트랙트(DAPP)을 개발합니다."
          />
        </AreaItems>
        <Footer>
          <FooterContent align="left">
            alan@plumbug.studio | 070-8065-3385 | 대표 : 김준수
            <br />
            주소 : 서울특별시 서초구 강남대로39길 5, 서초두산위브 B109호
          </FooterContent>
          <FooterContent align="right">
            © Copyright 2020 PlumbugStudio Inc. all rights reserved.
            <br />
            사업자 등록번호 : 884-87-02163
            <br />
            주식회사 플럼버그스튜디오
          </FooterContent>
        </Footer>
      </AreaContainer>
    </>
  );
};

export default Area;
